.login-container {
     display: flex;
    justify-content: center;
  align-self: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .login-box {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #1976d2; /* Blue color */
  }
  
  input {
    width: 100%;
    padding: 14px;
    font-weight: bold;
    margin: 10px 0;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  button {
    width: 100%;
    padding: 14px;
    font-weight: bold;
    margin-top: 10px;
    background-color: #ff5722; /* Deep orange */
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #e64a19; /* Darker deep-orange */
  }
  
  .google-signin button {
    background-color: #1976d2; /* Blue */
    color: white;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .google-signin button:hover {
    background-color: #1565c0; /* Darker blue */
  }
  
  .company-logo {
    max-width: 150px; /* Adjust the size of the logo */
    margin-bottom: -20px; /* Add space between the logo and the heading */
  }
  