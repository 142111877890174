/* Main dashboard container */
.dashboard-container {
  display: flex;
  height: 100vh;
}

/* Sidebar and content layout */
.dashboard-content {
  flex: 9;
  background-color: #f5f5f5;
  padding: 40px;
}

/* Dashboard header */
.dashboard-header {
  margin-bottom: 40px;
}

.dashboard-header h1 {
  font-size: 32px;
  color: #333;
}

/* Dashboard widgets container */
.dashboard-widgets {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns layout */
  gap: 20px; /* Space between widgets */
}

/* Individual widget styling */
.widget {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.widget:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.widget h3 {
  font-size: 20px;
  color: #1976d2;
  margin-bottom: 10px;
}

.widget p {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

/* Radial Gauge for storage */
.radial-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radial-gauge {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

/* Maintenance Mode specific widget styles */
.maintenance-enabled {
  background-color: #ffebee;
  border-left: 5px solid #d32f2f;
}

.maintenance-enabled p {
  color: #d32f2f;
}

.maintenance-disabled {
  background-color: #e8f5e9;
  border-left: 5px solid #388e3c;
}

.maintenance-disabled p {
  color: #388e3c;
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .dashboard-widgets {
    grid-template-columns: 1fr; /* Single column layout on small screens */
  }

  .widget {
    margin-bottom: 20px;
  }
}
