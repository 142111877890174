/* Container for the entire dashboard (sidebar + content) */
.settings-dashboard-container {
    display: flex;
    height: 100vh;
}

/* Main content container */
.settings-content {
    display: flex;
    flex-direction: column;
    flex: 9;
    background-color: #f4f4f9;
    height: 100vh;
    padding: 20px;
}

/* Header for Settings Page */
.settings-header h1 {
    font-size: 28px;
    color: #1976d2;
}

/* Tabs container */
.tabs-container {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
}

/* Tab buttons */
.tab-button {
    padding: 10px 20px;
    border: none;
    background-color: #ddd;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.tab-button:hover {
    background-color: #ccc;
}

.tab-button.active {
    background-color: #1976d2;
    color: white;
}

/* Settings section styles */
.settings-section {
    margin-bottom: 30px;
}

.settings-section h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.settings-item {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
}

/* Button styles */
.settings-button {
    padding: 10px 20px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.settings-button:hover {
    background-color: #1565c0;
}

/* List of existing admins */
.admin-list h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.admin-list ul {
    list-style-type: none;
    padding-left: 0;
}

/* Admin list item styles */
.admin-list-item {
    position: relative; /* Make the card a relatively positioned container */
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, border 0.3s ease-in-out;
}

/* Delete icon button for each admin */
.delete-user-btn {
    position: absolute;
    right: 10px; /* 10px from the right edge */
    top: 40%; /* Center vertically */
    transform: translateY(-40%); /* Adjust to vertically center */
    background-color: #e74c3c;
    border: none;
    color: white;
    border-radius: 50%; /* Make the button circular */
    width: 30px; /* Fixed width for circular button */
    height: 30px; /* Fixed height for circular button */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px; /* Size of the trash icon */
    transition: background-color 0.3s;
}

.delete-user-btn:hover {
    background-color: #c0392b; /* Darker red on hover */
}

/* Modal styles */
.admin-modal,
.delete-confirm-modal,
.maintenance-modal {
    background-color: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Overlay for modals */
.admin-modal-overlay,
.delete-modal-overlay,
.maintenance-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content */
.modal-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.modal-content input,
.modal-content select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

/* Modal action buttons */
.modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.confirm-add-btn,
.confirm-delete-btn,
.cancel-add-btn,
.cancel-delete-btn,
.confirm-btn,
.cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-add-btn,
.confirm-delete-btn,
.confirm-btn {
    background-color: #1976d2;
    color: white;
}

.confirm-add-btn:hover,
.confirm-delete-btn:hover,
.confirm-btn:hover {
    background-color: #1565c0;
}

.cancel-add-btn,
.cancel-delete-btn,
.cancel-btn {
    background-color: #e74c3c;
    color: white;
}

.cancel-add-btn:hover,
.cancel-delete-btn:hover,
.cancel-btn:hover {
    background-color: #c0392b;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .settings-content {
        padding: 10px;
    }

    .tabs-container {
        flex-direction: column;
        gap: 5px;
    }

    .tab-button {
        width: 100%;
    }
}
