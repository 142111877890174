/* Container for the entire dashboard (sidebar + content) */
.dashboard-container {
    display: flex;
    height: 100vh;
}


/* Main content container split into two sections (50% each) */
.user-management-content {
    display: flex;
    flex: 9; /* Takes up 80% of the screen (including sidebar) */
    background-color: #f4f4f9;
    height: 100vh;
}

/* Left section: User cards */
.user-card-section {
    flex: 1;
    padding: 40px;
    background-color: #ffffff;
    border-right: 1px solid #ddd;
    overflow-y: auto;
}

/* Header section for the page */
.user-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.user-management-header h1 {
    font-size: 28px;
    color: #1976d2;
}

/* Search input styling */
.search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 250px;
}

/* User statistics cards */
.user-stats-cards {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

/* Individual stat card */
.stat-card {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out;
}

.stat-card:hover {
    transform: scale(1.05);
}

.stat-card h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.stat-card p {
    font-size: 24px;
    font-weight: bold;
    color: #1976d2;
}

/* Premium user card styling */
.premium-card {
    background-color: #ffecb3; /* Light orange background */
    border: 1px solid #ffb74d;
}

.premium-card p {
    color: #ff9800; /* Dark orange text */
}

/* Active filter card */
.stat-card.active {
    background-color: #e3f2fd; /* Light blue for active card */
    border-color: #1976d2;
}

/* User card list styling */
.user-card-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

/* Individual user card */
.user-card {
    position: relative; /* Make the card a relatively positioned container */
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, border 0.3s ease-in-out;
}

.user-card:hover {
    transform: scale(1.05);
}

/* Highlight selected user with a deep orange border */
.user-card.selected {
    border: 2px solid #ff5722;
}

.user-card h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 5px;
}

.user-card p {
    font-size: 16px;
    color: #555;
}

/* Right section: User details */
.user-detail-section {
    flex: 1;
    padding: 40px;
    background-color: #f9f9f9;
    overflow-y: auto;
}

/* User detail card */
.user-detail-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.user-detail-card h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.user-detail-card p {
    font-size: 16px;
    color: #555;
}

/* Animal ads section */
.animal-ads-section {
    margin-top: 20px;
    display: grid; /* Use CSS grid to arrange items */
    grid-template-columns: repeat(2, 1fr); /* Two columns, each taking up 1 fraction of available space */
    gap: 20px; /* Add space between grid items */
}
/* Individual animal ad card: Image on left, info on right */
/* Delete icon button styling */
/* Circular delete icon button styling */
.delete-icon-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #e74c3c; /* Red background for delete button */
    border: none;
    border-radius: 50%; /* Make it circular */
    width: 30px; /* Fixed width for circular button */
    height: 30px; /* Fixed height for circular button */
    display: flex; /* Flexbox to center the icon */
    align-items: center; /* Vertically center icon */
    justify-content: center; /* Horizontally center icon */
    cursor: pointer;
    color: white; /* White color for the trash icon */
    font-size: 16px; /* Icon size */
    transition: background-color 0.3s;
}

.delete-icon-btn:hover {
    background-color: #c0392b; /* Darker red on hover */
}

/* Animal ad card styling for relative positioning */
.animal-ad-card {
    position: relative; /* Make the container relative so the icon can be positioned absolutely */
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex; /* Use flexbox to align image and text side by side */
    justify-content: flex-start; /* Align items at the start */
    align-items: center; /* Vertically center the image and text */
    gap: 20px; /* Add space between the image and the info */
    transition: transform 0.2s ease-in-out;
}

.animal-ad-card:hover {
    transform: scale(1.05);
}

/* Animal image with fixed size 150x150 */
.animal-thumbnail {
    width: 150px;
    height: 150px;
    object-fit: cover; /* Maintain aspect ratio, cropping if necessary */
    border-radius: 8px; /* Rounded corners */
}

/* Animal information section on the right of the image */
.animal-info {
    flex: 1; /* Allow the info to take up the remaining space */
    display: flex;
    flex-direction: column; /* Stack the info vertically */
    justify-content: start; /* Vertically center the text */
    gap: 4px; /* Reduce gap between text elements */
}

/* Animal name or breed heading */
.animal-info h4 {
    font-size: 18px;
    color: #333;
    margin: 0; /* Remove bottom margin */
    line-height: 1.2; /* Adjust line-height to reduce vertical space */
}

/* Paragraph for other animal information */
.animal-info p {
    font-size: 16px;
    color: #555;
    margin: 0; /* Remove bottom margin */
    line-height: 1.4; /* Adjust line-height to reduce vertical space */
}

/* Placeholder for when no thumbnail is available */
.no-thumbnail {
    width: 150px;
    height: 150px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #aaa;
}
.animal-ad-card h4 {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
}

.animal-ad-card p {
    font-size: 16px;
    color: #555;
}

.no-thumbnail {
    width: 100%;
    height: 150px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #aaa;
    margin-bottom: 10px;
}

/* Placeholder when no user is selected */
.user-placeholder {
    font-size: 18px;
    color: #888;
    text-align: center;
    margin-top: 50px;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .animal-ads-section {
      grid-template-columns: 1fr; /* On smaller screens, display 1 animal per row */
  }
}


/* Premium Information Section */
.premium-info {
    margin-top: 20px;
  }
  
  .premium-info p {
    font-size: 16px;
    color: #333;
  }
  
  /* Toggle Premium Button */
  .toggle-premium-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .toggle-premium-btn:hover {
    background-color: #1565c0;
  }

/* Center modal vertically and horizontally */
/* Center modal vertically and horizontally */
.premium-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.premium-modal {
    background-color: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

  
  .premium-options button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: black; /* Default text color */
    transition: background-color 0.3s;
  }
  
  .premium-options button:hover {
    background-color: #ddd;
  }
  
  .premium-options button.active {
    background-color: #1976d2;
    color: white;
  }
  
  .submit-btn, .cancel-btn {
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-btn {
    background-color: #1976d2;
    color: white;
  }
  
  .submit-btn:hover {
    background-color: #1565c0;
  }
  
  .cancel-btn {
    background-color: #e74c3c;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #c0392b;
  }

/* Delete User Button */
/* Small circular delete user button */
.delete-user-btn {
    position: absolute;
    right: 10px; /* 10px from the right edge */
    top: 40%; /* Center vertically */
    transform: translateY(-40%); /* Adjust to vertically center */
    background-color: #e74c3c;
    border: none;
    color: white;
    border-radius: 50%; /* Make the button circular */
    width: 30px; /* Fixed width for circular button */
    height: 30px; /* Fixed height for circular button */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px; /* Size of the trash icon */
    transition: background-color 0.3s;
}

.delete-user-btn:hover {
    background-color: #c0392b; /* Darker red on hover */
}

/* Delete confirmation modal styling */
.delete-modal {
    background-color: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.delete-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Modal action buttons */
.modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 15px;
}

.confirm-delete-btn {
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.confirm-delete-btn:hover {
    background-color: #c0392b;
}

.cancel-delete-btn {
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-delete-btn:hover {
    background-color: #34495e;
}

.search-input-animal {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 250px;
}