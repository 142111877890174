.dashboard-container {
    display: flex;
}

.banner-creator-content {
    flex: 9;
    display: flex;
    padding: 20px;
    gap: 20px;
}

.banner-list{
    flex: 1;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.banner-editor {
    flex: 1;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner-preview {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.row-group {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.color-picker {
    flex: 1;
}

.margin-input {
    flex: 1;
}

.input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.dropzone {
    border: 2px dashed #007bff;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    color: #007bff;
}

.save-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
}

.save-button:hover {
    background-color: #0056b3;
}

.banner-list h2 {
    font-size: 18px;
    margin-bottom: 15px;
}

.banner-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner-thumbnail {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    margin-right: 15px;
}
