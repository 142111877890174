* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background: #f4f4f9;
  height: 100vh;
  /* display:flex; */
  justify-content: center;
  align-items: center;
}


/* Notification style */

.Toastify__toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
}


.Toastify__toast--success {
  background-color: #4caf50; /* Success green */
  color: white;
  border-radius: 8px;
  padding: 12px;
}

.Toastify__toast--error {
  background-color: #f44336; /* Error red */
  color: white;
  border-radius: 8px;
  padding: 12px;
}

.Toastify__toast-body {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}
.Toastify__close-button {
  background: none;
  border: none;
  font-size: 10px;
  padding: 10px;
  width: auto;
  height: auto;
  position: absolute;
  right: 10px;
  bottom: 50%;
  transform: translateY(-50%);
  color: white; /* Matches the text color in the toast */
}

.Toastify__progress-bar {
  background: #ffffff; /* White progress bar */
}
