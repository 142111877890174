/* Container for the entire dashboard (sidebar + content) */
.animal-dashboard-container {
    display: flex;
    height: 100vh;
}

/* Fixed sidebar styling */


/* Main content container */
.animal-management-content {
    display: flex;
    flex: 9; /* Takes up 80% of the screen (including sidebar) */
    background-color: #f4f4f9;
    overflow-y: auto;
    height: 100vh;
}

/* Header for Animal Management */
.animal-management-header {
    align-items: start;
    justify-content: space-between;
    display: flex;
    padding: 20px;

}

.animal-management-header h1 {
    font-size: 28px;
    color: #1976d2;
}

/* Search input styling */
.animal-search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 250px;
}

/* Grid layout for animal ads */
.animal-ads-grid {
    flex: 9;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px; /* Gap between grid items */
}

/* Individual animal ad card */
.animal-ad-card {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    justify-content: space-between; /* Push content to the top and delete button to the bottom */
    gap: 10px;
    transition: transform 0.2s ease-in-out;
    height: 100%; /* Make the card fill the available height */
}

.animal-ad-card:hover {
    transform: scale(1.05);
}

/* Thumbnail image with fixed size 150x150 */
.animal-thumbnail {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* Placeholder for when no thumbnail is available */
.no-thumbnail {
    width: 150px;
    height: 150px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #aaa;
    margin-bottom: 10px;
}

/* Animal information section */
.animal-info {
    flex: 1; /* Allow the info section to take up remaining space */
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.animal-info h4 {
    font-size: 18px;
    color: #333;
    margin: 0;
    line-height: 1.2;
}

.animal-info p {
    font-size: 16px;
    color: #555;
    margin: 0;
    line-height: 1.4;
}

/* Delete icon button at the bottom */
.animal-delete-icon-btn {
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    align-self: flex-end; /* Align the delete button to the bottom right */
    transition: background-color 0.3s;
    width: 100%; /* Make button full width */
    text-align: center;
}

.animal-delete-icon-btn:hover {
    background-color: #c0392b;
}

/* Delete confirmation modal styling */
.animal-delete-modal {
    background-color: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.animal-delete-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Modal action buttons */
.modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 15px;
}

.confirm-delete-btn {
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.confirm-delete-btn:hover {
    background-color: #c0392b;
}

.cancel-delete-btn {
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-delete-btn:hover {
    background-color: #34495e;
}
