/* src/styles/Sidebar.css */

/* Sidebar container */
.sidebar {
    flex: 1; /* Takes up 20% of the width */
    background-color: #2c3e50;
    color: white;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  /* Sidebar logo */
  .sidebar-logo {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .sidebar-logo h2 {
    color: #ecf0f1;
    font-size: 24px;
  }
  
  /* Sidebar links */
  .sidebar-links {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-links li {
    margin-bottom: 20px;
  }
  
  .sidebar-links a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 10px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .sidebar-links a:hover {
    color: #1abc9c;
  }
  
  /* Highlight active link with deep orange background */
  .sidebar-links a.active {
    background-color: #ff5722;
    border-radius: 5px;
    color: white;
  }
  